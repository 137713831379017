import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'

import PageTitle from '../../PageTitle'

import { Grid, Typography, Button } from '@material-ui/core'
import ChevronRight from '@material-ui/icons/ChevronRight'

import CourseCard from '../../CourseCard'
import DiscussionCard from '../../Discussion/Discussion'
import LastCourseAccess from '../../LastCourseAccess'

import { useAccount } from '../../../lib/AccountContext'

import useStyles from './styles'

import Skeleton from './Skeleton'

const HOME_QUERY = gql`
  query getHome($accountId: String!, $limit: Float!, $offset: Float!) {
    getCourses(accountId: $accountId, limit: $limit, offset: $offset) {
      id
      title
      description
      courseVersionId
      defaultVersion
      progress
      versions
      latestVersion
    }
    getDiscussions(accountId: $accountId, limit: $limit, offset: $offset) {
      discussions {
        id
        title
        lessonId
        tags
        user {
          id
          profilePicture
          firstName
          lastName
        }
        createdAt
      }
    }
  }
`

const App = () => {
  const classes = useStyles()
  const { id: accountId } = useAccount()
  const router = useRouter()
  const { data, loading } = useQuery(HOME_QUERY, {
    variables: {
      accountId,
      limit: 3,
      offset: 0
    }
  })

  if (loading) {
    return <Skeleton />
  }

  return (
    <div>
      <PageTitle title="Home" />
      <LastCourseAccess />
      {data?.getCourses.length === 0 && (
        <Grid
          container
          direction="column"
          style={{ marginTop: 'auto', marginBottom: 'auto' }}
        >
          <img src={require('../../../assets/dog-comida.svg')} />

          <Grid
            container
            alignItems="center"
            direction="column"
            style={{ marginTop: 24 }}
          >
            <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
              Não há cursos para exibir
            </Typography>
            <Typography
              style={{ marginTop: 8, color: '#546E7A', fontSize: 16 }}
            >
              Conheça nossos cursos e faça parte do #teamdevpleno
            </Typography>
          </Grid>
        </Grid>
      )}
      {data?.getCourses.length > 0 && (
        <>
          <Grid container style={{ marginBottom: 24 }}>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              style={{ marginBottom: 26 }}
            >
              <Typography className={classes.subtitle}>
                Principais Cursos
              </Typography>
              <Link href="/app/courses" passHref>
                <a className={classes.viewAll}>Ver Todos</a>
              </Link>
            </Grid>

            <Grid container spacing={3}>
              {data?.getCourses.map((course) => (
                <Grid item xs={12} md={6} lg={4} key={course.id}>
                  <CourseCard
                    key={course.id}
                    accountId={accountId}
                    courseId={course.id}
                    title={course.title}
                    description={course.description}
                    defaultVersion={course.defaultVersion}
                    courseVersionId={course.courseVersionId}
                    progress={course.progress}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid container>
            <Typography className={classes.subtitle}>
              Últimas Discussões
            </Typography>
            {data?.getDiscussions.discussions.length === 0 && (
              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ marginTop: 72 }}
              >
                <div>
                  <img src={require('../../../assets/sem-discussao.svg')} />
                </div>
                <Grid
                  container
                  alignItems="center"
                  direction="column"
                  style={{ marginTop: 24 }}
                >
                  <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
                    Não há nenhuma discussão para ver no momento
                  </Typography>
                </Grid>
              </Grid>
            )}
            {data?.getDiscussions.discussions.length > 0 && (
              <>
                {data?.getDiscussions.discussions.map((discussion) => (
                  <DiscussionCard
                    key={discussion.id}
                    discussion={discussion}
                    scope="discussions"
                  />
                ))}

                <Grid container justify="center">
                  <Button
                    className={classes.viewMore}
                    onClick={() => router.push('/app/discussions')}
                  >
                    Ver Mais Discussões
                    <ChevronRight style={{ marginLeft: 8 }} />
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </div>
  )
}
export default App
