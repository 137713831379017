import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center'
    },
    user: {
      color: '#fff'
    },
    innerWrapper: {
      marginLeft: 12
    },
    discussionAuthor: {
      fontSize: 14,
      fontWeight: 'bold',
      lineHeight: 1.6
    },
    discussionCreatedAt: {
      fontSize: 14,
      lineHeight: 1.6,
      color: '#546E7A'
    }
  })
)

export default useStyles
