import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      fontWeight: 'bold',
      background: '#F5E5FF',
      textTransform: 'uppercase'
    }
  })
)

export default useStyles
