import React from 'react'

import { Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const HomeSkeleton = () => (
  <>
    <Skeleton
      variant="rect"
      width="100%"
      height={170}
      style={{ borderRadius: 16, marginBottom: 26 }}
    />
    <Grid
      container
      justify="space-between"
      alignItems="center"
      style={{ marginBottom: 24 }}
    >
      <Skeleton animation="wave" variant="text" width={220} height={50} />
      <Skeleton animation="wave" variant="text" width={100} height={25} />
    </Grid>
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <Skeleton
          variant="rect"
          animation="wave"
          style={{
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20
          }}
          height={196}
        />
      </Grid>
      <Grid item xs={4}>
        <Skeleton
          variant="rect"
          animation="wave"
          style={{
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20
          }}
          height={196}
        />
      </Grid>
      <Grid item xs={4}>
        <Skeleton
          variant="rect"
          animation="wave"
          style={{
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20
          }}
          height={196}
        />
      </Grid>
    </Grid>
    <Skeleton
      animation="wave"
      variant="text"
      width={220}
      height={50}
      style={{ marginTop: 26 }}
    />
    <Skeleton
      animation="wave"
      variant="text"
      width={350}
      height={30}
      style={{ marginBottom: 10, marginTop: 24 }}
    />

    <Grid container alignItems="center" style={{ marginBottom: 16 }}>
      <Skeleton animation="wave" variant="circle" width={40} height={40} />
      <Grid style={{ marginLeft: 12 }}>
        <Skeleton animation="wave" variant="text" width={150} />
        <Skeleton animation="wave" variant="text" width={150} />
      </Grid>
    </Grid>
    <Grid container spacing={1}>
      <Grid item>
        <Skeleton animation="wave" variant="text" width={55} />
      </Grid>
      <Grid item>
        <Skeleton animation="wave" variant="text" width={55} />
      </Grid>
      <Grid item>
        <Skeleton animation="wave" variant="text" width={55} />
      </Grid>
    </Grid>

    <Skeleton
      animation="wave"
      variant="text"
      width={350}
      height={30}
      style={{ marginBottom: 10, marginTop: 26 }}
    />
    <Grid container alignItems="center" style={{ marginBottom: 16 }}>
      <Skeleton animation="wave" variant="circle" width={40} height={40} />
      <Grid style={{ marginLeft: 12 }}>
        <Skeleton animation="wave" variant="text" width={150} />
        <Skeleton animation="wave" variant="text" width={150} />
      </Grid>
    </Grid>
    <Grid container spacing={1}>
      <Grid item>
        <Skeleton animation="wave" variant="text" width={55} />
      </Grid>
      <Grid item>
        <Skeleton animation="wave" variant="text" width={55} />
      </Grid>
      <Grid item>
        <Skeleton animation="wave" variant="text" width={55} />
      </Grid>
    </Grid>

    <Skeleton
      animation="wave"
      variant="text"
      width={350}
      height={30}
      style={{ marginBottom: 10, marginTop: 26 }}
    />
    <Grid container alignItems="center" style={{ marginBottom: 16 }}>
      <Skeleton animation="wave" variant="circle" width={40} height={40} />
      <Grid style={{ marginLeft: 12 }}>
        <Skeleton animation="wave" variant="text" width={150} />
        <Skeleton animation="wave" variant="text" width={150} />
      </Grid>
    </Grid>
    <Grid container spacing={1}>
      <Grid item>
        <Skeleton animation="wave" variant="text" width={55} />
      </Grid>
      <Grid item>
        <Skeleton animation="wave" variant="text" width={55} />
      </Grid>
      <Grid item>
        <Skeleton animation="wave" variant="text" width={55} />
      </Grid>
    </Grid>
  </>
)

export default HomeSkeleton
