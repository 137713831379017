import React from 'react'
import MuiChip, { ChipProps } from '@material-ui/core/Chip'

import useStyles from './styles'

const Chip: React.FC<ChipProps> = ({ label, ...props }) => {
  const classes = useStyles()
  return (
    <MuiChip
      className={classes.root}
      size="small"
      color="primary"
      label={label}
      variant="outlined"
      {...props}
    />
  )
}

export default Chip
