import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'

import Author from '../Author'

import { Grid, Typography } from '@material-ui/core'
//import { ChatBubbleOutline } from '@material-ui/icons'

import Chip from '../../Chip'

import useStyles from './styles'

const Discussion = ({ discussion, lessonId, scope }) => {
  const classes = useStyles()
  const router = useRouter()
  const { courseId, courseVersionId } = router.query
  const {
    id,
    title,
    createdAt,
    tags,
    user: { firstName, lastName, profilePicture }
  } = discussion

  const handleNavigateToDiscussion = useCallback(() => {
    switch (scope) {
      case 'course':
        router.push(
          `/app/courses/[courseId]/version/[courseVersionId]/discussions/[discussionId]`,
          `/app/courses/${courseId}/version/${courseVersionId}/discussions/${id}`
        )
        break
      case 'lesson':
        router.push(
          `/app/courses/[courseId]/version/[courseVersionId]/learn/[lessonId]/discussions/[discussionId]`,
          `/app/courses/${courseId}/version/${courseVersionId}/learn/${lessonId}/discussions/${id}`
        )
        break
      case 'discussions':
        router.push(`/app/discussions/[discussionId]`, `/app/discussions/${id}`)
        break
      default:
        break
    }
  }, [router, courseId, courseVersionId, lessonId, id, scope])

  const parsedTags = JSON.parse(tags)
  return (
    <div className={classes.root} onClick={handleNavigateToDiscussion}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 16, marginTop: 26 }}
      >
        <Typography className={classes.discussionTitle}>{title}</Typography>
      </Grid>
      <div style={{ marginBottom: 16 }}>
        <Author
          author={`${firstName} ${lastName}`}
          createdAt={createdAt}
          src={profilePicture}
        />
      </div>
      <Grid container spacing={1}>
        {parsedTags.map((tag) => (
          <Grid key={tag.id} item>
            <Chip label={tag.name} />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

Discussion.propTypes = {
  lessonId: PropTypes.string,
  scope: PropTypes.string,
  discussion: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    createdAt: PropTypes.string,
    tags: PropTypes.array,
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      profilePicture: PropTypes.string
    })
  }).isRequired
}

export default Discussion
