import { createStyles, makeStyles } from '@material-ui/core/styles'

import courseBg from '../../../assets/course-card.svg'
import keepLearnImg from '../../../assets/keep-learn.svg'

const useStyles = makeStyles((theme) =>
  createStyles({
    keepWatching: {
      fontSize: 14,
      marginBottom: 14,
      lineHeight: 1,
      color: '#546E7A'
    },
    keepLearning: {
      display: 'flex',
      paddingLeft: 28,
      paddingRight: 28,
      borderRadius: 16,
      background: `url(${keepLearnImg}) no-repeat center`,
      backgroundSize: 'cover',
      height: 170
    },
    lesson: {
      fontSize: 34,
      fontWeight: 'bold',
      lineHeight: 1,
      marginBottom: 14
    },
    lessonDescription: {
      fontSize: 16,
      marginBottom: 16,
      lineHeight: 1.5,
      color: '#546E7A'
    },
    progress: {
      fontSize: 16,
      marginTop: 10,
      color: '#546E7A',
      lineHeight: 1
    },
    subtitle: {
      fontSize: 24,
      fontWeight: 'bold',
      lineHeight: 1
    },
    viewAll: {
      fontSize: 16,
      fontWeight: 'bold',
      lineHeight: 1,
      color: theme.palette.primary.main,
      textDecoration: 'none'
    },
    viewMore: {
      margin: '16px 0'
    },
    actions: {
      marginLeft: -8
    },
    button: {
      marginLeft: 8,
      marginRight: 8
    },
    course: {
      padding: 24,
      background: `url(${courseBg}) no-repeat center`,
      backgroundSize: 'cover',
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20
    },
    courseTitle: {
      fontSize: 24,
      fontWeight: 'bold',
      marginBottom: 10,
      lineHeight: 1
    },
    autor: {
      fontSize: 14,
      marginBottom: 16,
      lineHeight: 1
    },
    progressCourse: {
      fontSize: 14,
      marginTop: 6,
      marginBottom: 16,
      color: '#546E7A',
      lineHeight: 1
    },
    courseModule: {
      fontSize: 16,
      fontWeight: '500',
      marginBottom: 8,
      color: '#fff',
      lineHeight: 1
    },
    courseTitleWhite: {
      fontSize: 24,
      fontWeight: 'bold',
      color: '#fff',
      marginBottom: 18,
      lineHeight: 1
    },
    discussion: {
      fontSize: 16,
      lineHeight: 1.6,
      color: '#546E7A',
      marginBottom: 22
    }
  })
)

export default useStyles
