import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useRouter } from 'next/router'
import { gql } from 'apollo-boost'

import { Grid, Typography, Button } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

import { useAccount } from '../../lib/AccountContext'

import useStyles from './styles'

export const GET_LAST_COURSE_ACCESS = gql`
  query($accountId: String!, $courseId: String) {
    getLastCourseAccess(accountId: $accountId, courseId: $courseId) {
      id
      courseId
      courseVersionId
      lessonId
      courseTitle
      moduleTitle
      lessonTitle
      lastAccess
    }
  }
`

const LastCourseAccess = () => {
  const classes = useStyles()
  const router = useRouter()
  const { courseId } = router.query
  const { id: accountId } = useAccount()
  const { data, loading } = useQuery(GET_LAST_COURSE_ACCESS, {
    variables: {
      accountId,
      courseId
    }
  })

  if (loading) {
    return (
      <Skeleton
        variant="rect"
        width="100%"
        height={170}
        style={{ borderRadius: 16, marginBottom: 32 }}
      />
    )
  }

  return (
    <>
      {data?.getLastCourseAccess?.lessonTitle && (
        <Grid container spacing={2} style={{ marginBottom: 32 }}>
          <Grid item xs={12}>
            <Grid
              container
              className={classes.keepLearning}
              alignItems="center"
            >
              {/* <img src={require('../../assets/react.svg')} alt="react" /> */}
              <Grid
                direction="column"
                justify="center"
                // style={{ marginLeft: 32 }}
              >
                <Typography className={classes.courseModule}>
                  Continuar assistindo
                </Typography>
                <Typography className={classes.courseTitleWhite}>
                  {data.getLastCourseAccess.lessonTitle}
                </Typography>
                <Grid className={classes.actions}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() =>
                      router.push(
                        '/app/courses/[courseId]/version/[courseVersionId]/learn/[lessonId]',
                        `/app/courses/${data.getLastCourseAccess.courseId}/version/${data.getLastCourseAccess.courseVersionId}/learn/${data.getLastCourseAccess.lessonId}`
                      )
                    }
                  >
                    Retomar Lição
                  </Button>
                  {router.pathname !==
                    '/app/courses/[courseId]/version/[courseVersionId]' && (
                    <Button
                      className={classes.button}
                      variant="outlined"
                      color="secondary"
                      onClick={() =>
                        router.push(
                          '/app/courses/[courseId]/version/[courseVersionId]',
                          `/app/courses/${data.getLastCourseAccess.courseId}/version/${data.getLastCourseAccess.courseVersionId}`
                        )
                      }
                    >
                      Acessar Curso
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default LastCourseAccess
