import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      borderBottom: '1px solid #CFD8DC',
      paddingBottom: 28,
      width: '100%',
      '&:hover': {
        opacity: 0.8,
        cursor: 'pointer'
      }
    },
    keepWatching: {
      fontSize: 14,
      marginBottom: 14,
      lineHeight: 1,
      color: '#546E7A'
    },
    lesson: {
      fontSize: 34,
      fontWeight: 'bold',
      lineHeight: 1,
      marginBottom: 14
    },
    lessonDescription: {
      fontSize: 16,
      marginBottom: 16,
      lineHeight: 1.5,
      color: '#546E7A'
    },
    progress: {
      fontSize: 16,
      marginTop: 10,
      color: '#546E7A',
      lineHeight: 1
    },
    title: {
      fontSize: 24,
      fontWeight: 'bold',
      lineHeight: 1
    },
    subTitle: {
      marginBottom: 12,
      fontSize: 12,
      lineHeight: 1
    },
    selectedButton: {
      backgroundColor: '#E7F9F0'
    },
    discussion: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 16
    },
    viewAll: {
      fontSize: 16,
      fontWeight: 'bold',
      lineHeight: 1,
      color: theme.palette.primary.main,
      textDecoration: 'none'
    },
    actions: {
      marginLeft: -8
    },
    search: {
      border: '1px solid #CFD8DC',
      borderRadius: 8,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up('xl')]: {
        marginLeft: 16,
        width: 387
      }
    },
    searchIcon: {
      paddingRight: 16,
      height: 24,
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    inputRoot: {
      color: 'inherit',
      width: '100%'
    },
    inputInput: {
      padding: '0 12px',
      // vertical padding + font size from searchIcon
      transition: theme.transitions.create('width')
    },
    searchWrapper: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: 'auto'
      }
    },
    buttonWrapper: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: 'auto'
      }
    },
    courseTitle: {
      fontSize: 24,
      fontWeight: 'bold',
      marginBottom: 10,
      lineHeight: 1
    },
    autor: {
      fontSize: 14,
      marginBottom: 16,
      lineHeight: 1
    },
    progressCourse: {
      fontSize: 14,
      marginTop: 6,
      marginBottom: 16,
      color: '#546E7A',
      lineHeight: 1
    },
    courseModule: {
      fontSize: 16,
      fontWeight: '500',
      marginBottom: 8,
      color: '#fff',
      lineHeight: 1
    },
    courseTitleWhite: {
      fontSize: 24,
      fontWeight: 'bold',
      color: '#fff',
      marginBottom: 18,
      lineHeight: 1
    },
    discussionTitle: {
      fontSize: 20,
      fontWeight: 'bold',
      lineHeight: 1
    },
    discussionAnswer: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 1
    },
    discussionAnswerCount: {
      fontSize: 16,
      color: '#546E7A',
      lineHeight: 1,
      marginLeft: 8,
      marginTop: -4
    },
    discussionAuthor: {
      fontSize: 14,
      fontWeight: 'bold',
      marginBottom: 4,
      lineHeight: 1
    },

    discussionCreatedAt: {
      fontSize: 14,
      lineHeight: 1.6,
      color: '#546E7A'
    }
  })
)

export default useStyles
