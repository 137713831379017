import React from 'react'
import PropTypes from 'prop-types'

import { handleTimeDifference } from '../../../utils/handleTimeDifference'

import { Avatar, Grid, Typography } from '@material-ui/core'

import useStyles from './styles'

const Author = ({ author, src, createdAt }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Avatar src={src} alt="profile-picture" />
      <Grid
        className={classes.innerWrapper}
        container
        direction="column"
        justify="center"
      >
        <Typography className={classes.discussionAuthor}>{author === 'null null' ? 'Usuário' : author}</Typography>
        <Typography className={classes.discussionCreatedAt}>
          {`Há ${handleTimeDifference(new Date(), createdAt)}`}
        </Typography>
      </Grid>
    </div>
  )
}

Author.propTypes = {
  src: PropTypes.string,
  author: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired
}

export default Author
